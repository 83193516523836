@tailwind base;
@tailwind components;
@tailwind utilities;

.bullet-list {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 1em;
}

.bullet-list li {
  display: flex;
}

.bullet-list li::before {
  content: "•";
  color: #fff; /* Bullet color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
